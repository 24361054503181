<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->

    </div>

    <b-navbar-nav
      v-if="raidActive"
      class="nav align-items-center ml-auto mt-1"
    >
      <b-alert
        variant="info"
        show
        class="align-items-center"
      >
        <div class="alert-body align-items-center">
          <span><strong>Raid Active!</strong> Messages are sent to raided Channel: {{ raidedChannel }}</span>
        </div>
      </b-alert>
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <i class="bx bx-user" />
      <div class="form-label-group has-icon-left">
        <div class="form-control-position">
          <i class="bx bx-user" />
        </div>
        <label for="iconLabelLeft">Icon Left, Default Input</label>
      </div>
      <b-nav-item href="https://discord.gg/streamerzone">
        <b-img
          width="23%"
          :src="require('@/assets/images/svg/discord.svg')"
        />
      </b-nav-item>
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavItem,
  BLink, BNavbarNav, BAlert, BIcon, BImg,
} from 'bootstrap-vue'

import store from '@/store'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BIcon,
    BAlert,
    BNavItem,
    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      raidedChannel: 'error',
      raidActive: false,
    }
  },
  computed: {
    getRaidActive() {
      return store.state.app.raidActive
    },
  },
  watch: {
    getRaidActive(value) {
      this.raidActive = value
      this.raidedChannel = store.state.app.raidedChannel
    },
  },
  mounted() {
    if (this.$store.state['app/raidActive']) {
      this.raidedChannel = this.$store.state['app/raidedChannel']
      this.raidActive = true
    }
  },
}
</script>
